@import "../../variables";

.navigation {
  width: 100%;
  height: 67px;
  background: #ff0000;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  .burger {
    z-index: 999;

    @include sm {
      display: none;
    }
  }
}
