@import "../../variables";

.ExpandMore {
  .showMore {
    text-align: inherit;
    color: $darkBlue;
    cursor: pointer;
    margin-top: 8px;
    font-size: 0.8em;
    font-weight: bold;
  }
  .MuiCollapse-wrapper {
    text-align: left;
  }
}
