.Accordion {
  .MuiExpansionPanelSummary-root.MuiExpansionPanelSummary-root {
    padding: 0 8px;
    border-top: 1px dashed #eee;

    .MuiExpansionPanelSummary-content {
      display: flex;
      align-items: center;

      .remove {
        display: none;
      }

      svg {
        margin-right: 10px;
      }

      &.Mui-expanded {
        .add {
          display: none;
        }

        .remove {
          display: block;
        }
      }
    }
  }
  
  .MuiCollapse-wrapperInner {
    .MuiExpansionPanelDetails-root {
      padding: 0 0 16px;
    }
  }
}
