@import "../../variables";

.Home {
  canvas.chartjs-render-monitor {
    filter: drop-shadow(-9px 9px 6px #00000022);
  }
  .chartContainer {
    width: 55%;
    display: block;
    margin: 0 auto;
    margin-top: -20px;
    padding: 20px 0;
    position: relative;
  }

  .chartLegend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    &.chartLegend--absolute {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .legendItem {
        background: transparent;
        transform: scale(0.75);
        font-weight: bold;
      }
    }
    .legendItem {
      margin: 10px 0;
      border-radius: 10px;
      background: $bgColor;
      padding: 10px;
      display: flex;
      min-width: 140px;
      &.low,
      &.medium,
      &.high {
        &:before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          background: $green;
          border-radius: 3px;
          margin-right: 10px;
        }
      }
      &.medium:before {
        background: $yellow;
      }
      &.high:before {
        background: $red;
      }
    }
  }

  .statusList {
    line-height: 2;

    td {
      vertical-align: top;
    }
  }
}
