@import "../../variables";

.layout {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .content-wrapper {
    flex: 1 0 auto;
  }
}

.MuiDrawer-paper.MuiPaper-root {
  background: rgba($primaryColor, 0.9);
  border-left: 1px solid rgba($primaryColor, 0.8);
  .MuiDivider-root {
    background: #ffffff44;
  }
  a,
  li {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
  }
}
