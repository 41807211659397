@import "../../variables";

.EditTextField {
  width: 100%;
  &:not(.disabled) {
    border: 1px solid $primaryColor;
  }
  > div {
    padding: 0 6px;
  }
}
