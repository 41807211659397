@import "variables";
@import "typography";

html {
  height: 100%;
  overflow: auto;
}

html,
body {
  font-family: $fontDefault;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.7;
  letter-spacing: 0px;
  margin: 0;
  padding: 0;
  background: $bgColor;
  color: #444444;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $primaryColor;
}

input:-internal-autofill-selected {
  padding-left: 8px;
  background: #ffffff22;
}

.logo img {
  height: 50px;
}

.contentBox {
  border-radius: 10px;
  background: #fff;
  padding: 20px;
  height: 100%;
  width: 100%;
  &--transparent {
    background: transparent;
    p {
      font-size: 15px;
    }
  }
  &--background {
    background: #f5f7fd;
  }
}

h1 {
  font-size: 28px;
  font-family: $fontSpecial;
  color: $primaryColor;
}

h2 {
  font-size: 28px;
  margin-bottom: 0;
  color: $primaryColor;
  font-family: $fontSpecial;
}
h3 {
  font-size: 24px;
  color: #444444;
  font-family: $fontSpecial;
}

h4 {
  font-size: 18px;
  color: $primaryColor;
  font-family: $fontSpecial;
}

h6 {
  font-size: 13px;
  font-family: $fontDefault;
}

div > h3:first-child,
div > h4:first-child {
  margin-top: 0;
  color: $primaryColor;
}

button svg:last-child {
  margin-left: 8px;
}
button svg:first-child {
  margin-right: 8px;
}

.MuiButtonBase-root.MuiTab-root:not(:last-child) {
  border-right: 2px solid #f5f7fd;
}

.MuiTabs-root {
  .MuiTabs-scrollButtons {
    width: 20px;
  }
  .MuiTab-root {
    font-size: 14px;
    min-width: 0;
    padding: 10px 24px;
    white-space: nowrap;
    @include sm {
      padding: 20px 50px;
    }
  }
  .Mui-selected {
    font-weight: normal;
  }
}

div[class^="MUIDataTableHeadCell"] {
  color: $primaryColor;
  font-weight: normal;
}

[class^="MUIDataTableBodyCell-stackedCommon"] {
  height: auto !important;
  padding: 8px !important;
  @include md {
    height: 24px !important;
    padding: 16px !important;
  }
}

.MuiTableCell-root,
.MuiButton-root,
.MuiTab-root,
.MuiTypography-h6 {
  font-family: $fontDefault !important;
  text-transform: none !important;
}

.MuiTableRow-root[class*="MUIDataTableBodyRow-responsiveStacked"] {
  margin-top: 2px;
  td:nth-child(odd) {
    font-weight: normal;
  }
}

.MuiButtonBase-root.MuiButton-root {
  font-size: 14px;
  padding: 10px 20px;
  svg {
    font-size: 15px;
  }
}

.MuiTab-textColorPrimary {
  color: $primaryColor !important;
}

.swal2-styled.swal2-confirm {
  background: $primaryColor !important;
}
