@font-face {
  font-family: "Clan";
  src: url("/assets/font/ClanW05-Medium.eot");
  src: url("/assets/font/ClanW05-Medium.eot?#iefix") format("embedded-opentype"),
    url("/assets/font/ClanW05-Medium.woff2") format("woff2"),
    url("/assets/font/ClanW05-Medium.woff") format("woff"),
    url("/assets/font/ClanW05-Medium.ttf") format("truetype");
  font-weight: normal;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/font/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('/assets/font/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/font/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/font/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/font/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/font/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face{
  font-family: 'SparkasseWeb';
  src: url('/assets/font/Sparkasse_web_Rg.woff') format('woff'),
       url('/assets/font/Sparkasse_web_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SparkasseWeb';
  src: url('/assets/font/Sparkasse_web_Bd.woff') format('woff'),
       url('/assets/font/Sparkasse_web_Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SparkasseWebLight';
  src: url('/assets/font/Sparkasse_web_Lt.woff') format('woff'),
       url('/assets/font/Sparkasse_web_Lt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SparkasseHeadWeb';
  src: url('/assets/font/SparkasseHead_web_Rg.woff') format('woff'),
       url('/assets/font/SparkasseHead_web_Rg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}