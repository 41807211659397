@import "../../variables";

.EditDataAccordion {
  margin: 16px 0;
  .MuiButtonBase-root {
    // margin-left: 8px;
    .MuiIconButton-label {
      svg {
        margin: 0;
        color: #ccc;
        transition: 0.3s color;
        font-size: 0.8em;
        &:hover {
          color: #888;
        }
      }
    }
  }
  .addItem {
    width: 18px;
    height: 18px;
    border: 2px solid $primaryColor;
    position: relative;
    border-radius: 2px;
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
    &:before,
    &:after {
      content: "";
      width: 10px;
      height: 2px;
      position: absolute;
      left: 2px;
      top: 6px;
      transform-origin: 50% 50%;
      background: $primaryColor;
    }
    &:after {
      transform: rotate(90deg);
    }
  }

  .showMore {
    text-align: center;
    color: $primaryColor;
    margin-top: 8px;
  }

  .MuiExpansionPanelSummary-content {
    flex-direction: column;
    &.Mui-expanded {
      margin: 12px 0;
    }

    .MuiExpansionPanelSummary-content {
      align-items: center;
    }

    .MuiExpansionPanelDetails-root {
      padding: 0;
    }
  }
}

.EditDataAccordion:first-of-type {
  margin-top: 0;
}
