@import "../../variables";

.LogoutInfo {
  text-align: right;
  position: absolute;
  top: 67px; // navigation height
  right: 22px;
  background: #ff0000;
  color: #fff;
  padding: 15px 30px;
  z-index: 998;
  svg {
    margin-right: 4px;
  }
  p {
    margin: 0;
  }
  a {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0;
    margin-top: 8px;
    display: inline-block;
  }
  h4 {
    margin-bottom: 0;
    color: #fff !important;
  }
}
