$breakpoint-xs: "0px";
$breakpoint-sm: "600px";
$breakpoint-md: "1140px";
$breakpoint-lg: "1280px";
$breakpoint-xl: "1920px";

@mixin xs {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

$primaryColor: #ff0000;
$secondaryColor: #ff0000;
$textColor: #777;
$lightTextColor: #737373;
$bgColor: #f5f7fd;

$darkBlue: #ff0000;
$red: #ff0000;
$green: #37c391;
$yellow: #ffd719;

$fontDefault: "SparkasseWeb", sans-serif;
$fontSpecial: "SparkasseHeadWeb", sans-serif;

$fontSizeDefault: 1rem;
$fontSizeSmall: 0.857rem;
$fontSizeExtraSmall: 0.714rem;

$fontSizeHeadline1: 2.7rem;
$fontSizeHeadline2: 2rem;
$fontSizeHeadline3: 1.6rem;
$fontSizeHeadline4: 1rem;

$boxPadding: 32px 24px;
