@import "../../variables";

.InnerLayout {
  padding-top: 190px;
  @include sm {
    padding-top: 160px;
  }

  padding-bottom: 3em;
  position: relative;
}
