@import "../../variables";

.footer {
  background: #444444;
  color: #fff;
  padding: 50px 0;
  img {
    margin: 10px 0;
  }
  a {
    margin-left: 25px;
    color: #fff;
    font-size: 1em;
  }
}
